import { NavLink, Outlet } from "react-router-dom";
import Avatar from "../assets/img/avatars/01.jpg";

const AccountLayout = ({ children }) => {
  return (
    <section className="">
      <div className="container pt-5 mt-4 mt-lg-5 mt-xl-5">
        <div className="row pt-sm-2 pt-lg-0">
          <aside className="col-lg-3" style={{ marginTop: "-75px" }}>
            <div
              className="offcanvas-lg offcanvas-start sticky-lg-top pe-lg-3 pe-xl-4"
              id="accountSidebar"
            >
              <div
                className="d-none d-lg-block"
                style={{ height: "105px" }}
              ></div>

              {/* <!-- Header --> */}
              <div className="offcanvas-header d-lg-block py-3 p-lg-0">
                <div className="d-flex flex-row flex-lg-column align-items-center align-items-lg-start">
                  <div
                    className="flex-shrink-0 bg-body-secondary border rounded-circle overflow-hidden"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <img src={Avatar} alt="Avatar" />
                  </div>
                  <div className="pt-lg-3 ps-3 ps-lg-0">
                    <h6 className="mb-1">Michael Williams</h6>
                    <p className="fs-sm mb-0">m.williams@example.com</p>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn-close d-lg-none"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#accountSidebar"
                  aria-label="Close"
                ></button>
              </div>

              {/* <!-- Body (Navigation) --> */}
              <div className="offcanvas-body d-block pt-2 pt-lg-4 pb-lg-0 mb-5">
                <nav className="list-group list-group-borderless">
                  <NavLink
                    activeClassName="active"
                    to="/account/profile"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-user fs-base opacity-75 me-2"></i>
                    My Profile
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/account/my-listing"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-layers fs-base opacity-75 me-2"></i>
                    My listings
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/account/reviews"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-star fs-base opacity-75 me-2"></i>
                    Reviews
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/account/favorites"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-user fs-base opacity-75 me-2"></i>
                    My Favorites
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/account/manage-payment"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-credit-card fs-base opacity-75 me-2"></i>
                    Payment details
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/account/account-settings"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-settings fs-base opacity-75 me-2"></i>
                    Account settings
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/account/help-center"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-help-circle fs-base opacity-75 me-2"></i>
                    Help center
                  </NavLink>
                </nav>
                {/* <nav className="list-group list-group-borderless pt-3">
                  <NavLink
                    activeClassName="active"
                    to="/account/help-center"
                    className="list-group-item list-group-item-action d-flex align-items-center"
                  >
                    <i className="fi-log-out fs-base opacity-75 me-2"></i>
                    Sign out
                  </NavLink>
                </nav> */}
              </div>
            </div>
          </aside>
          <div className="col-lg-9">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountLayout;
