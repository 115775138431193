import React, { useState, useEffect, useContext } from "react";
import Avatar from "../../assets/img/avatars/01.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { fetchFavoriteCars } from "../../_services/carsService";
import { WishlistContext } from "../../context/WishlistContext";


const Header = ({ onLoginClick }) => {
  const navigate = useNavigate()
  const { wishlistCount } = useContext(WishlistContext);
  const { isLoggedIn, logout, userInfo } = useContext(AuthContext);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getCars();
  }, [wishlistCount]);

  const getCars = async () => {
    try {
      const data = await fetchFavoriteCars();
      setCars(data);
    } catch (err) {
      setError(err);
    }
  };

  const handleSellCar = () => {
    if (isLoggedIn) {
      navigate("/sell-car");
    } else {
      const loginEvent = new Event("openLoginModal");
      window.dispatchEvent(loginEvent); // Dispatch global event
      return;
    }
  }

  const handleLoginClick = () => {
    const loginEvent = new Event("openLoginModal");
    window.dispatchEvent(loginEvent); // Dispatch global event
  };

  return (
    <header
      className="navbar navbar-expand-lg fixed-top navbar-stuck bg-white"
      data-scroll-header
      style={{ borderBottom: "solid 1px #e3e3e3" }}
    >
      <div className="container">
        <Link
          to="/"
          className="navbar-brand text-primary fw-bold fs-3 me-3 me-xl-4"
        >
          Xlr8.
        </Link>
        <button
          className="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {isLoggedIn && (
          <div className="dropdown d-none d-lg-block order-lg-3 my-n2">
            <span className="d-block py-2 cursor-pointer">
              <img
                className="rounded-circle"
                src={Avatar}
                width="40"
                alt="Robert Fox"
              />
              <span className="ms-2">
                {userInfo && userInfo.email ? userInfo.email.split("@")[0] : ""}
              </span>
            </span>

            <div className="dropdown-menu dropdown-menu-dark dropdown-menu-end">
              <div
                className="d-flex align-items-start border-bottom border-light px-3 py-1 mb-2"
                style={{ width: "16rem" }}
              >
                <img
                  className="rounded-circle"
                  src={Avatar}
                  width="48"
                  alt="Robert Fox"
                />
                <div className="ps-2">
                  <h6 className="fs-base text-light mb-0">
                    {userInfo && userInfo.name
                      ? userInfo.name
                      : userInfo?.email.split("@")[0]}
                  </h6>
                  <span className="star-rating star-rating-sm">
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                  </span>
                  {userInfo?.phone_number && (
                    <div className="fs-xs py-2">
                      {userInfo && userInfo.phone_number
                        ? userInfo.phone_number
                        : ""}
                      <br />
                      {userInfo && userInfo.email ? userInfo.email : ""}
                    </div>
                  )}
                </div>
              </div>

              <Link to="/account/profile" className="dropdown-item">
                <i className="fi-user me-2"></i>Personal Info
              </Link>
              <a className="dropdown-item" href="#">
                <i className="fi-lock me-2"></i>Password &amp; Security
              </a>

              <Link to="/account/my-listing" className="dropdown-item">
                <i className="fi-car me-2"></i>My Listing
              </Link>

              <Link to="/account/favorites" className="dropdown-item">
                <i className="fi-heart me-2"></i>Wishlist
                <span className="badge bg-faded-light ms-2">{cars.length}</span>
              </Link>

              <a className="dropdown-item" href="#">
                <i className="fi-star me-2"></i>Reviews
              </a>
              <a className="dropdown-item" href="#">
                <i className="fi-bell me-2"></i>Notifications
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                Help
              </a>
              <a className="dropdown-item" onClick={logout} href="#">
                Sign Out
              </a>
            </div>
          </div>
        )}

        {!isLoggedIn && (
          <a
            className="btn btn-link btn-sm d-none d-lg-block order-lg-3"
            onClick={handleLoginClick}
            data-bs-toggle="modal"
          >
            <i className="fi-user me-2"></i>Sign in
          </a>
        )}

        <div
          className="collapse navbar-collapse justify-content-center order-lg-2"
          id="navbarNav"
        >
          <ul
            className="navbar-nav navbar-nav-scroll"
            style={{ maxHeight: "35rem" }}
          >
            <li className="nav-item">
              <NavLink to="/" exact className="nav-link">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                to="/auctions"
                className="nav-link"
              >
                Auctions
              </NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Latest News
              </a>
            </li>
            <button
              onClick={handleSellCar}
              className="btn btn-sm btn-primary order-lg-3 me-3"
            >
              Sell Car
            </button>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
