import Catalog17 from "../../../assets/img/car-finder/catalog/17.jpg";
import Catalog09 from "../../../assets/img/car-finder/catalog/09.jpg";
import { useEffect, useState } from "react";
import { fetchLatestCars } from "../../../_services/carsService";
import ImageSlider from "../../../components/ImageSlider";

function TopOffers() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCars = async () => {
      try {
        const data = await fetchLatestCars();
        console.log(data);
        setCars(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getCars();
  }, []);
  if (cars.length)
    return (
      <section className="container pt-sm-1 pb-5 mb-md-4 mt-5">
        <div className="d-sm-flex align-items-center justify-content-between mb-1 pb-sm-2">
          <h2 className="h3 mb-2 mb-sm-0">Top offers</h2>
          <a className="btn btn-link fw-normal px-0" href="#">
            View all offers
            <i className="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i>
          </a>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="card card-hover h-100">
              <div className="tns-carousel-wrapper card-img-top card-img-hover">
                {/* <a className="img-overlay" href="#"></a>
                <div className="position-absolute start-0 top-0 pt-3 ps-3">
                  <span className="d-table badge bg-info mb-1">New</span>
                  <span className="d-table badge bg-success mb-1">$25,000</span>
                </div>
                <div className="content-overlay end-0 top-0 pt-3 pe-3">
                  <button
                    className="btn btn-icon btn-xs text-primary rounded-circle"
                    type="button"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Add to Wishlist"
                  >
                    <i className="fi-heart"></i>
                  </button>
                </div> */}
                {/* <div className="tns-carousel-inner">
                <img src={Catalog17} alt="Image" />
              </div> */}
                <div>
                  {/* <img src={Catalog17} alt="Image" /> */}
                  <ImageSlider car={cars[0]} />
                </div>
              </div>
              <div className="card-body pb-0">
                <h3 className="h6 mb-1">
                  {cars?.length && (
                    <a className="nav-link" href="#">
                      {cars[0].make} {cars[0].model}
                    </a>
                  )}
                </h3>
                <div className="text-primary fw-bold mb-1">${cars[0].current_bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                {/* <div className="fs-sm opacity-70">
                  <i className="fi-map-pin me-1"></i>New York
                </div> */}
              </div>
              <div className="card-footer bg-transparent border-0 pt-0 pb-4">
                <div className="border-top pt-3">
                  <div className="row row-cols-2 g-2 fs-sm">
                    <div className="col d-flex align-items-center gap-2">
                      <i className="fi-map-pin"></i>
                      {cars[0].year}
                    </div>
                    <div className="col d-flex align-items-center gap-2">
                      <i className="fi-tachometer"></i>
                      {cars[0].odometer_reading >= 1e6 ? (cars[0].odometer_reading / 1e6).toFixed(1) + 'M' : cars[0].odometer_reading >= 1e3 ? (cars[0].odometer_reading / 1e3).toFixed(1) + 'K' : cars[0].odometer_reading.toString()} mi
                    </div>
                    <div className="col d-flex align-items-center gap-2">
                      <i className="fi-gas-pump"></i>
                      {cars[0].fuel_type}
                    </div>
                    <div className="col d-flex align-items-center gap-2">
                      <i className="fi-gearbox"></i>
                      {cars[0].transmission}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card card-hover card-horizontal mb-4">
              <div className="tns-carousel-wrapper card-img-top card-img-hover w-100 h-100">
                <ImageSlider car={cars[1]} />
                {/* <a className="img-overlay" href="#"></a> */}
                {/* <div className="top-0 position-absolute w-100 h-100">
                <div
                  className="bg-size-cover bg-position-center bg-size-cover w-100 h-100"
                  style={{
                    backgroundImage: "url(" + Catalog09 + ")",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div> */}
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between pb-1">
                  {/* <span className="fs-sm me-3">1995</span> */}
                  {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare2"
                  />
                  <label className="form-check-label fs-sm" htmlFor="compare2">
                    Compare
                  </label>
                </div> */}
                </div>
                <h3 className="h6 mb-1">
                  {cars?.length && (
                    <a className="nav-link" href="#">
                      {cars[1].make} {cars[1].model}
                    </a>
                  )}
                </h3>
                <div className="text-primary fw-bold mb-1">${cars[1].current_bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                {/* <div className="fs-sm opacity-70">
                  <i className="fi-map-pin me-1"></i>Chicago
                </div> */}
                <div className="card-footer mt-4 bg-transparent border-0 pt-0 px-0 pb-2">
                  <div className="border-top pt-3">
                    <div className="row row-cols-2 g-2 fs-sm">
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-map-pin"></i>
                        {cars[1].year}
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-tachometer"></i>
                        {cars[1].odometer_reading >= 1e6 ? (cars[1].odometer_reading / 1e6).toFixed(1) + 'M' : cars[1].odometer_reading >= 1e3 ? (cars[1].odometer_reading / 1e3).toFixed(1) + 'K' : cars[1].odometer_reading.toString()} mi
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-gas-pump"></i>
                        {cars[1].fuel_type}
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-gearbox"></i>
                        {cars[1].transmission}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-hover card-horizontal">
              <div className="tns-carousel-wrapper card-img-top w-100 card-img-hover">
                <ImageSlider car={cars[2]} />
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between pb-1">
                  {/* <span className="fs-sm me-3">2018</span> */}
                  {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare3"
                  />
                  <label className="form-check-label fs-sm" htmlFor="compare3">
                    Compare
                  </label>
                </div> */}
                </div>
                <h3 className="h6 mb-1">
                  {cars.length && (
                    <a className="nav-link" href="#">
                      {cars[2].make} {cars[2].model}
                    </a>
                  )}
                </h3>
                <div className="text-primary fw-bold mb-1">${cars[2].current_bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                {/* <div className="fs-sm opacity-70">
                  <i className="fi-map-pin me-1"></i>San Francisco
                </div> */}
                <div className="card-footer mt-4 bg-transparent border-0 pt-0 px-0 pb-2">
                  <div className="border-top pt-3">
                    <div className="row row-cols-2 g-2 fs-sm">
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-map-pin"></i>
                        {cars[2].year}
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-tachometer"></i>
                        {cars[2].odometer_reading >= 1e6 ? (cars[2].odometer_reading / 1e6).toFixed(1) + 'M' : cars[2].odometer_reading >= 1e3 ? (cars[2].odometer_reading / 1e3).toFixed(1) + 'K' : cars[2].odometer_reading.toString()} mi
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-gas-pump"></i>
                        {cars[2].fuel_type}
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-gearbox"></i>
                        {cars[2].transmission}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default TopOffers;
