import React, { createContext, useState, useEffect, useMemo } from "react";
import { fetchCurrentUserInfo } from "../_services/userService";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      setLoading(false);
      fetchUserInfo(token);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const fetchUserInfo = async (token) => {
    try {
      const userInfo = await fetchCurrentUserInfo();
      setUserInfo(userInfo);
    } catch (err) {
      console.error("Failed to fetch user info:", err);
    }
  };

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      userInfo,
      login,
      logout,
      loading,
      fetchUserInfo,
    }),
    [isLoggedIn, userInfo]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
