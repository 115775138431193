import Catalog8 from "../../assets/img/car-finder/catalog/08.jpg";
import React, { useContext, useEffect, useState } from "react";
import AddToWishListBtn from "../../components/addToWishListBtn";
import { deleteCarFromWishlist, fetchFavoriteCars, fetchMyCars } from "../../_services/carsService";
import { Link } from "react-router-dom";
import RemoveFromWishListBtn from "../../components/removeFromWishListBtn";
import { ToastContext } from "../../context/ToastContext";
import ImageSlider from "../../components/ImageSlider";

function MyCars() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    getCars();
  }, []);

  const getCars = async () => {
    try {
      const data = await fetchMyCars();
      setCars(data.cars);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const removeFromWishList = async (carId) => {
    try {
      await deleteCarFromWishlist(carId);
      getCars();
      showToast("Removed from Wishlist!", "dark");
    } catch (err) {
      setError(err);
      showToast("Failed to update wishlist.", "danger");
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pb-4 mt-5 mb-2">
        <h2 className="me-3 mb-0">{cars.length} Cars</h2>
      </div>
      <div className="d-sm-flex align-items-center justify-content-between pb-4 mb-2">
        <div className="d-flex align-items-center me-sm-4">
          <label
            className="fs-sm fw-normal text-nowrap mb-0 me-2 pe-1 form-label"
            for="sortbyTop"
          >
            <i className="fi-arrows-sort mt-n1 me-2"></i>Sort by:
          </label>
          <select className="me-sm-4 form-select form-select-sm" id="sortbyTop">
            <option value="newest">Newest</option>
            <option value="popular">Popular</option>
            <option value="priceLH">Price: Low - High</option>
            <option value="priceHL">Price: High - Low</option>
          </select>
          <div
            className="d-none d-md-block border-end border-light"
            style={{ height: "1.25rem" }}
          ></div>
        </div>
      </div>

      <div className="row">
        {cars.map((car, index) => (
          <div className="col-lg-4 mb-4">
            <Link
              className="text-decoration-none"
              key={index}
              to={`/auctions/${car.id}`}
            >
              <div className="card card-hover h-100">
                <div className="card-img-top card-img-hover">
                  <ImageSlider car={car} add="no" />
                  {/* <img src={Catalog8} alt="Image" /> */}
                </div>
                <div className="card-body px-0">
                  <div className="d-flex px-3 align-items-center justify-content-between pb-1">
                    <div className="text-primary fw-bold mb-1">
                      ${car.odometer_reading}
                    </div>
                    <span className="fs-sm me-3">
                      {car.odometer_reading >= 1e6 ? (car.odometer_reading / 1e6).toFixed(1) + 'M' : car.odometer_reading >= 1e3 ? (car.odometer_reading / 1e3).toFixed(1) + 'K' : car.odometer_reading.toString()} mi
                    </span>
                  </div>
                  <h3 className="h6 mb-1 px-3">
                    <a className="nav-link">
                      {car.year} {car.make} {car.model}
                    </a>
                  </h3>
                  {/* <div className="fs-sm text-muted px-3">
                    <i className="fi-map-pin me-1"></i> Boston, 560068
                  </div> */}
                </div>

                <div className="card-footer px-0 bg-transparent border-0 pt-0 pb-3">
                  <div className="border-top pt-3 px-3">
                    <div className="row row-cols-2 g-2 fs-sm">
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-petrol"></i>
                        {car.fuel_type}
                      </div>
                      <div className="col d-flex align-items-center gap-2">
                        <i className="fi-gearbox"></i>
                        {car.transmission}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="d-flex align-items-center justify-content-between py-2">
        <div className="d-flex align-items-center me-sm-4">
          {/* <label
            className="fs-sm fw-normal text-nowrap mb-0 me-2 pe-1 form-label"
            for="sortbyBottom"
          >
            <i className="fi-arrows-sort mt-n1 me-2"></i>Sort by:
          </label>
          <select
            className="me-sm-4 form-select form-select-sm"
            id="sortbyBottom"
          >
            <option value="newest">Newest</option>
            <option value="popular">Popular</option>
            <option value="priceLH">Price: Low - High</option>
            <option value="priceHL">Price: High - Low</option>
          </select>
          <div
            className="d-none d-md-block border-end border-light"
            style={{ height: "1.25rem" }}
          ></div> */}
        </div>

        <nav aria-label="Pagination">
          <ul className="mb-0 pagination">
            <li className="page-item active">
              <span className="page-link">
                1<span className="visually-hidden">(current)</span>
              </span>
            </li>
            <li className="page-item">
              <a className="page-link" role="button" tabindex="0" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" role="button" tabindex="0" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" role="button" tabindex="0" href="#">
                <span aria-hidden="true">…</span>
                <span className="visually-hidden">More</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" role="button" tabindex="0" href="#">
                8
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" role="button" tabindex="0" href="#">
                <i className="fi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default MyCars;
