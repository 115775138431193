import axiosInstance from "../_utils/axiosInstance";

// Fetch list of cars
export const fetchCars = async () => {
  try {
    const response = await axiosInstance.get("/cars");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching cars:",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Failed to fetch car list.";
  }
};


// Fetch list of cars
export const fetchLatestCars = async () => {
  try {
    const response = await axiosInstance.get("/cars/latest");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching cars:",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Failed to fetch latest car list.";
  }
};


// Fetch list of cars
export const fetchPopularCars = async () => {
  try {
    const response = await axiosInstance.get("/cars/popular");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching cars:",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Failed to fetch popular car list.";
  }
};

// Fetch my cars
export const fetchMyCars = async () => {
  try {
    const response = await axiosInstance.get("/cars/mycars");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching cars:",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Failed to fetch car list.";
  }
};


// Fetch my favorite cars
export const fetchFavoriteCars = async () => {
  try {
    const response = await axiosInstance.get("/cars/fav");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching cars:",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Failed to fetch car list.";
  }
};

// Fetch Car details
export const fetchCardetails = async (carId) => {
  try {
    const response = await axiosInstance.get(`/cars/${carId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching car details:",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Failed to fetch car details.";
  }
};

// Add car to wishlist
export const addCarToWishlist = async (carId) => {
  try {
    const response = await axiosInstance.post(`/cars/fav?car_id=${carId}`, {});
    return response
  } catch (error) {
    console.error(
      "Error Adding Car to Wishlist",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error Adding Car to Wishlist. Please try again.";
  }
};


// Delete Car from wishlist
export const deleteCarFromWishlist = async (carId) => {
  try {
    const response = await axiosInstance.delete(`/cars/fav?car_id=${carId}`, {});
    return response
  } catch (error) {
    console.error(
      "Error Removing Car from Wishlist",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error Removing Car from Wishlist. Please try again.";
  }
};


// Upload new car
export const uploadCar = async (data) => {
  try {
    const response = await axiosInstance.post(`/cars`, data);
    return response
  } catch (error) {
    console.error(
      "Error Uploading Car",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error Uploading Car. Please try again.";
  }
};

// Place a Bid
export const placeCarBid = async (payload) => {
  try {
    const response = await axiosInstance.post(`/bids/place`, payload);
    return response
  } catch (error) {
    console.error(
      "Error Placing Bid",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error Placing Bid. Please try again.";
  }
};

export const updateCarBid = async (payload) => {
  try {
    const response = await axiosInstance.put(`/bids/modify`, payload);
    return response
  } catch (error) {
    console.error(
      "Error Placing Bid",
      error.response?.data || error.detail
    );
    throw error.response?.data?.detail || "Error Placing Bid. Please try again.";
  }
};

export const getCarBid = async () => {
  try {
    const response = await axiosInstance.get(`/bids`);
    return response
  } catch (error) {
    console.error(
      "Error Placing Bid",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error Placing Bid. Please try again.";
  }
};


export const getHighestBidOnCar = async (carId) => {
  try {
    const response = await axiosInstance.get('/bids/highest_bid_on_car', {
      params: {
        car_id: carId,  // Replace 'carIdValue' with the actual value of car_id
      },
    });
    return response
  } catch (error) {
    console.error(
      "Error Placing Bid",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error Placing Bid. Please try again.";
  }
};
