import Catalog8 from "../../assets/img/car-finder/catalog/08.jpg";
import Catalog4 from "../../assets/img/car-finder/catalog/04.jpg";
import { useContext, useEffect, useState } from "react";
import { fetchCars } from "../../_services/carsService";
import { Link, NavLink } from "react-router-dom";
import AddToWishListBtn from "../../components/addToWishListBtn";
import { ToastContext } from "../../context/ToastContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from "../../context/AuthContext";
import ImageSlider from "../../components/ImageSlider";

const CarListingPage = () => {
  const [filters, setFilters] = useState({
    body_type: [],
    year: {
      start: 0,
      end: 0
    },
    make: "",
    model: "",
    price: {
      start: 0,
      end: 0
    },
    drivetrain: [],
    fuel_type: [],
    transmission: [],
  })
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [cars, setCars] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { showToast } = useContext(ToastContext);
  const { isLoggedIn } = useContext(AuthContext);
  useEffect(() => {
    setCars(data);
    let temp = data;
    temp = temp.filter((t) => {
      if ((!filters.body_type.length || filters.body_type.includes(t.body_type)) && (!filters.drivetrain.length || filters.drivetrain.includes(t.drivetrain)) && (!filters.fuel_type.length || filters.fuel_type.includes(t.fuel_type)) && (!filters.transmission.length || filters.transmission.includes(t.transmission)) && (filters.make === "" || filters.make === t.make))
        return t;
    })
    setCars(temp)

  }, [data, filters])
  const setFilterArrays = (event) => {
    const { name, checked, value } = event.target;
    console.log(name, checked, value);

    if (checked) {
      let temp = filters[name];
      if (!temp.includes(value))
        temp.push(value)
      setFilters((prev) => {
        return {
          ...prev,
          [name]: temp
        }
      })
    } else {
      let temp = filters[name];
      if (temp.includes(value))
        temp = temp.filter((t) => t !== value)
      setFilters((prev) => {
        return {
          ...prev,
          [name]: temp
        }
      })
    }
  }
  const setMake = (event) => {
    const { name, value } = event.target;
    if (value === "any") {
      setFilters((prev) => {
        return {
          ...prev,
          [name]: ""
        }
      })
    } else
      setFilters((prev) => {
        return {
          ...prev,
          [name]: value
        }
      })
  }
  useEffect(() => {
    console.log(filters);
  }, [filters])
  useEffect(() => {
    const getCars = async () => {
      try {
        const data = await fetchCars();
        setData(data);
      } catch (err) {
        setError(err);
        showToast("Failed to load cars.", "danger");
      } finally {
        setLoading(false);
      }
    };

    getCars();
  }, [isLoggedIn]);

  const sliderSettings = {
    infinite: false,
    slidesToShow: 4,
    arrows: true,
    className: "slides",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For mobile devices (e.g., <=768px width)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // For tablets (optional)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // Larger screens (e.g., desktops, >= 1200px width)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <section className="">
      {loading ? (
        <div
          className="my-5 py-5 w-100 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <span className="loader"></span>
        </div>
      ) : (
        <div className="mt-5 container">
          <div className="row">
            <aside
              className="pe-xl-4 py-5 col-lg-3"
              style={{ borderRight: "solid 1px #e3e3e3" }}
            >
              <div className="offcanvas-lg offcanvas-start">
                <div className="bg-transparent offcanvas-header">
                  <h5 className=" offcanvas-title">Filters</h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="py-lg-4 offcanvas-body">
                  <div className="pb-3 mb-4 border-bottom border-light">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h3 className="h6 mb-0">Filters</h3>
                      {/* <button
                        type="button"
                        className="btn btn-link fw-normal fs-sm p-0"
                      >
                        Clear all
                      </button> */}
                    </div>
                    {/* <div className="nav nav-pills flex-row fs-sm mx-0">
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        Under 2019<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        Crossover<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        Sedan<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        SUV<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        Diesel<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        Gasoline<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                    <div className="mb-2 me-2 nav-item">
                      <button className="px-3 nav-link">
                        Hybrid<i className="fi-x fs-xxs ms-2"></i>
                      </button>
                    </div>
                  </div> */}
                  </div>
                  <div className="pb-4 mb-2">
                    <h3 className="h6 ">Body Type</h3>
                    <div
                      data-simplebar="init"
                      className="simplebar-no-autohide "
                      style={{ maxHeight: "11rem" }}
                    >
                      <div className="simplebar-wrapper" style={{ margin: 0 }}>
                        <div className="simplebar-height-auto-observer-wrapper">
                          <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                          <div
                            className="simplebar-offset"
                            style={{ right: 0, bottom: 0 }}
                          >
                            <div
                              className="simplebar-content-wrapper"
                              tabindex="0"
                              role="region"
                              aria-label="scrollable content"
                              style={{
                                height: "auto",
                                overflow: "hidden scroll",
                              }}
                            >
                              <div
                                className="simplebar-content"
                                style={{ padding: 0 }}
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-0"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="Sedan"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-0"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">Sedan</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-1"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="SUV"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-1"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">SUV</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-2"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="Hatchback"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-2"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">Hatchback</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-4"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="Coupe"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-4"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">Coupe</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-6"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="Truck"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-6"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">Truck</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-8"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="Convertible"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-8"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">Convertible</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="bodyType-9"
                                    className="form-check-input"
                                    onChange={setFilterArrays}
                                    name="body_type"
                                    value="Van"
                                  />
                                  <label
                                    title=""
                                    for="bodyType-9"
                                    className="form-check-label"
                                  >
                                    <span className="fs-sm">Van</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="simplebar-placeholder"
                          style={{ width: "auto", height: "300px" }}
                        ></div>
                      </div>
                      <div
                        className="simplebar-track simplebar-horizontal"
                        style={{ visibility: "hidden" }}
                      >
                        <div
                          className="simplebar-scrollbar simplebar-visible"
                          style={{ width: 0, display: "none" }}
                        ></div>
                      </div>
                      <div
                        className="simplebar-track simplebar-vertical"
                        style={{ visibility: "visible" }}
                      >
                        <div
                          className="simplebar-scrollbar simplebar-visible"
                          style={{
                            height: "103px",
                            transform: "translate3d(0px, 0px, 0px)",
                            display: "block",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="pb-4 mb-2">
                    <h3 className="h6  pt-1">Year</h3>
                    <div className="d-flex align-items-center">
                      <select className=" w-100 form-select">
                        <option value="from" disabled="" selected="">
                          From
                        </option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                      </select>
                      <div className="mx-2">—</div>
                      <select className=" w-100 form-select">
                        <option value="to" disabled="">
                          To
                        </option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019" selected="">
                          2019
                        </option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="pb-4 mb-2">
                    <h3 className="h6 ">Make</h3>
                    <select className=" mb-2 form-select" name="make" onChange={setMake}>
                      <option value="any">
                        Any make
                      </option>
                      <option value="Audi">Audi</option>
                      <option value="Ford">Ford</option>
                      <option value="Honda">Honda</option>
                      <option value="Hyundai">Hyundai</option>
                      <option value="BMW">BMW</option>
                      <option value="Kia">Kia</option>
                      <option value="Tesla">Tesla</option>
                      <option value="Mercedes">Mercedes</option>
                      <option value="Nissan">Nissan</option>
                      <option value="Opel">Opel</option>
                      <option value="Renault">Renault</option>
                      <option value="Toyota">Toyota</option>
                    </select>
                  </div>
                  {/* <div className="pb-4 mb-2"> */}
                  {/* <h3 className="h6 ">Price</h3> */}
                  {/* <div className="range-slider-light">
                  <div className="range-slider range-slider-light" style={{position: 'relative'}}>
                    <div className="range-slider-track range-slider-track-0" style={{position: 'absolute', left: 0, right: '215.625px'}}></div>
                    <div className="range-slider-track range-slider-track-1" style={{position: 'absolute', left: '60.375px', right: '100.625px'}}></div>
                    <div className="range-slider-track range-slider-track-2" style={{position: 'absolute', left: '175.375px', right: 0}}></div>
                    <div className="range-slider-handle range-slider-handle-0 " tabindex="0" role="slider" aria-orientation="horizontal" aria-valuenow="25000" aria-valuemin="4000" aria-valuemax="100000" aria-label="Lower handle" aria-valuetext="Handle value 25000" style="position: absolute; touch-action: none; z-index: 1; left: 60.375px;">
                      <div className="range-slider-tooltip">$ 25000</div>
                    </div>
                    <div className="range-slider-handle range-slider-handle-1 " tabindex="0" role="slider" aria-orientation="horizontal" aria-valuenow="65000" aria-valuemin="4000" aria-valuemax="100000" aria-label="Upper handle" aria-valuetext="Handle value 65000" style="position: absolute; touch-action: none; z-index: 2; left: 175.375px;">
                      <div className="range-slider-tooltip">$ 65000</div>
                    </div>
                  </div>
                </div> */}
                  {/* <div className="d-flex align-items-center">
                      <div className="w-100 pe-2">
                        <input
                          step="1000"
                          name="minPrice"
                          type="number"
                          className="form-control"
                          value="25000"
                        />
                      </div>
                      <div className="text-muted">—</div>
                      <div className="w-100 ps-2">
                        <input
                          step="1000"
                          name="maxPrice"
                          type="number"
                          className="form-control"
                          value="65000"
                        />
                      </div>
                    </div>
                    <div className="mt-3 form-check form-switch">
                      <input
                        type="checkbox"
                        id="negotiated-price"
                        className="form-check-input"
                      />
                      <label
                        title=""
                        for="negotiated-price"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Negotiated price</span>
                      </label>
                    </div> */}
                  {/* </div> */}
                  <div className="pb-4 mb-2">
                    <h3 className="h6 ">Drivetrain</h3>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="drivetrain-0"
                        className="form-check-input"
                        name="drivetrain"
                        onChange={setFilterArrays}
                        value="AWD"
                      />
                      <label
                        title=""
                        for="drivetrain-0"
                        className="form-check-label"
                      >
                        <span className="fs-sm">AWD</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="drivetrain-1"
                        className="form-check-input"
                        name="drivetrain"
                        onChange={setFilterArrays}
                        value="FWD"
                      />
                      <label
                        title=""
                        for="drivetrain-1"
                        className="form-check-label"
                      >
                        <span className="fs-sm">FWD</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="drivetrain-2"
                        className="form-check-input"
                        name="drivetrain"
                        onChange={setFilterArrays}
                        value="RWD"
                      />
                      <label
                        title=""
                        for="drivetrain-2"
                        className="form-check-label"
                      >
                        <span className="fs-sm">RWD</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="drivetrain-2"
                        className="form-check-input"
                        name="drivetrain"
                        onChange={setFilterArrays}
                        value="4WD"
                      />
                      <label
                        title=""
                        for="drivetrain-2"
                        className="form-check-label"
                      >
                        <span className="fs-sm">4WD</span>
                      </label>
                    </div>
                  </div>
                  <div className="pb-4 mb-2">
                    <h3 className="h6 ">Fuel Type</h3>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="fuelType-2"
                        className="form-check-input"
                        name="fuel_type"
                        onChange={setFilterArrays}
                        value="Petrol"
                      />
                      <label
                        title=""
                        for="fuelType-2"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Petrol</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="fuelType-0"
                        className="form-check-input"
                        name="fuel_type"
                        onChange={setFilterArrays}
                        value="Diesel"
                      />
                      <label
                        title=""
                        for="fuelType-0"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Diesel</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="fuelType-1"
                        className="form-check-input"
                        name="fuel_type"
                        onChange={setFilterArrays}
                        value="Electric"
                      />
                      <label
                        title=""
                        for="fuelType-1"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Electric</span>
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="fuelType-3"
                        className="form-check-input"
                        name="fuel_type"
                        onChange={setFilterArrays}
                        value="Hybrid"
                      />
                      <label
                        title=""
                        for="fuelType-3"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Hybrid</span>
                      </label>
                    </div>
                  </div>
                  <div className="pb-4 mb-2">
                    <h3 className="h6 ">Transmission</h3>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="transmission-0"
                        className="form-check-input"
                        name="transmission"
                        onChange={setFilterArrays}
                        value="Automatic"
                      />
                      <label
                        title=""
                        for="transmission-0"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Automatic</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="transmission-1"
                        className="form-check-input"
                        name="transmission"
                        onChange={setFilterArrays}
                        value="Manual"
                      />
                      <label
                        title=""
                        for="transmission-1"
                        className="form-check-label"
                      >
                        <span className="fs-sm">Manual</span>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="transmission-1"
                        className="form-check-input"
                        name="transmission"
                        onChange={setFilterArrays}
                        value="CVT"
                      />
                      <label
                        title=""
                        for="transmission-1"
                        className="form-check-label"
                      >
                        <span className="fs-sm">CVT</span>
                      </label>
                    </div>
                  </div>
                  <div className="pb-4 mb-2">
                    {/* <h3 className="h6  pt-1">Mileage</h3>
                    <div className="d-flex align-items-center">
                      <input
                        step="500"
                        placeholder="From"
                        type="number"
                        className="w-100 form-control"
                      />
                      <div className="mx-2">—</div>
                      <input
                        step="500"
                        placeholder="To"
                        type="number"
                        className="w-100 form-control"
                      />
                    </div> */}
                  </div>
                  {/* <div className="pb-4 mb-2">
                  <h3 className="h6 ">Color</h3>
                  <div
                    data-simplebar="init"
                    className="simplebar-no-autohide "
                    style={{ maxHeight: "11rem" }}
                  >
                    <div className="simplebar-wrapper" style={{ margin: 0 }}>
                      <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                      </div>
                      <div className="simplebar-mask">
                        <div
                          className="simplebar-offset"
                          style={{ right: 0, bottom: 0 }}
                        >
                          <div
                            className="simplebar-content-wrapper"
                            tabindex="0"
                            role="region"
                            aria-label="scrollable content"
                            style={{
                              height: "auto",
                              overflow: "hidden scroll",
                            }}
                          >
                            <div
                              className="simplebar-content"
                              style={{ padding: 0 }}
                            >
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-0"
                                  className="form-check-input"
                                  value="Amber"
                                />
                                <label
                                  title=""
                                  for="color-0"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Amber</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-1"
                                  className="form-check-input"
                                  value="Azure"
                                />
                                <label
                                  title=""
                                  for="color-1"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Azure</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-2"
                                  className="form-check-input"
                                  value="Beige"
                                />
                                <label
                                  title=""
                                  for="color-2"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Beige</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-3"
                                  className="form-check-input"
                                  value="Black"
                                />
                                <label
                                  title=""
                                  for="color-3"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Black</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-4"
                                  className="form-check-input"
                                  value="Blue"
                                />
                                <label
                                  title=""
                                  for="color-4"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Blue</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-5"
                                  className="form-check-input"
                                  value="Brown"
                                />
                                <label
                                  title=""
                                  for="color-5"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Brown</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-6"
                                  className="form-check-input"
                                  value="Camouflage green"
                                />
                                <label
                                  title=""
                                  for="color-6"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">
                                    Camouflage green
                                  </span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-7"
                                  className="form-check-input"
                                  value="Charcoal"
                                />
                                <label
                                  title=""
                                  for="color-7"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Charcoal</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-8"
                                  className="form-check-input"
                                  value="Gray"
                                />
                                <label
                                  title=""
                                  for="color-8"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Gray</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-9"
                                  className="form-check-input"
                                  value="Green"
                                />
                                <label
                                  title=""
                                  for="color-9"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Green</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-10"
                                  className="form-check-input"
                                  value="Gold"
                                />
                                <label
                                  title=""
                                  for="color-10"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Gold</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-11"
                                  className="form-check-input"
                                  value="Purple"
                                />
                                <label
                                  title=""
                                  for="color-11"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Purple</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-12"
                                  className="form-check-input"
                                  value="Red"
                                />
                                <label
                                  title=""
                                  for="color-12"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Red</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-13"
                                  className="form-check-input"
                                  value="White"
                                />
                                <label
                                  title=""
                                  for="color-13"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">White</span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id="color-14"
                                  className="form-check-input"
                                  value="Yellow"
                                />
                                <label
                                  title=""
                                  for="color-14"
                                  className="form-check-label"
                                >
                                  <span className="fs-sm">Yellow</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="simplebar-placeholder"
                        style={{ width: "auto", height: "450px" }}
                      ></div>
                    </div>
                    <div
                      className="simplebar-track simplebar-horizontal"
                      style={{ visibility: "hidden" }}
                    >
                      <div
                        className="simplebar-scrollbar simplebar-visible"
                        style={{ width: 0, display: "none" }}
                      ></div>
                    </div>
                    <div
                      className="simplebar-track simplebar-vertical"
                      style={{ visibility: "visible" }}
                    >
                      <div
                        className="simplebar-scrollbar simplebar-visible"
                        style={{
                          height: "68px",
                          transform: "translate3d(0px, 0px, 0px)",
                          display: "block",
                        }}
                      ></div>
                    </div>
                  </div>
                </div> */}
                  {/* <div className="pb-2">
                  <h3 className="h6 ">Sellers</h3>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="seller-0"
                      className="form-check-input"
                      value="Dealers only"
                    />
                    <label title="" for="seller-0" className="form-check-label">
                      <span className="fs-sm">Dealers only</span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="seller-1"
                      className="form-check-input"
                      value="Private Sellers Only"
                    />
                    <label title="" for="seller-1" className="form-check-label">
                      <span className="fs-sm">Private Sellers Only</span>
                    </label>
                  </div>
                </div> */}
                </div>
              </div>
            </aside>
            <div className="col-lg-9 ps-4 py-5">
              <nav aria-label="breadcrumb" className="mb-3 pt-md-2 pt-lg-4">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Auctions
                  </li>
                </ol>
              </nav>

              <div className="d-flex align-items-center justify-content-between pb-4 mb-2">
                <h1 className="me-3 mb-0">{cars.length} Auctions</h1>
              </div>
              <div className="d-sm-flex align-items-center justify-content-between pb-4 mb-2">
                <div className="d-flex align-items-center me-sm-4">
                  <label
                    className="fs-sm fw-normal text-nowrap mb-0 me-2 pe-1 form-label"
                    for="sortbyTop"
                  >
                    <i className="fi-arrows-sort mt-n1 me-2"></i>Sort by:
                  </label>
                  <select
                    className="me-sm-4 form-select form-select-sm"
                    id="sortbyTop"
                  >
                    <option value="newest">Newest</option>
                    <option value="popular">Popular</option>
                    <option value="priceLH">Price: Low - High</option>
                    <option value="priceHL">Price: High - Low</option>
                  </select>
                  <div
                    className="d-none d-md-block border-end border-light"
                    style={{ height: "1.25rem" }}
                  ></div>
                </div>
              </div>

              <div className="row content">
                {/* <Slider {...sliderSettings}> */}
                {cars.map((car, index) => (
                  <div className="col-lg-4 mb-4">
                    <Link
                      className="text-decoration-none"
                      key={index}
                      to={`/auctions/${car.id}`}
                    >
                      <div className="card card-hover h-100">
                        <ImageSlider car={car} />
                        <div className="card-body px-0">
                          <div className="d-flex px-3 align-items-center justify-content-between pb-1">
                            <div className="text-primary fw-bold mb-1">
                              ${car.current_bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                            <span className="fs-sm me-3">
                              {car.odometer_reading >= 1e6 ? (car.odometer_reading / 1e6).toFixed(1) + 'M' : car.odometer_reading >= 1e3 ? (car.odometer_reading / 1e3).toFixed(1) + 'K' : car.odometer_reading.toString()} mi
                            </span>
                          </div>
                          <h3 className="h6 mb-1 px-3">
                            <a className="nav-link car-info">
                              {car.year} {car.make} {car.model}
                            </a>
                          </h3>
                          {/* <div className="fs-sm text-muted px-3">
                              <i className="fi-map-pin me-1"></i> Boston, 560068
                            </div> */}
                        </div>

                        <div className="card-footer px-0 bg-transparent border-0 pt-0 pb-3">
                          <div className="border-top pt-3 px-3">
                            <div className="row row-cols-2 g-2 fs-sm">
                              <div className="col d-flex align-items-center gap-2">
                                <i className="fi-petrol"></i>
                                {car.fuel_type}
                              </div>
                              <div className="col d-flex align-items-center gap-2">
                                <i className="fi-gearbox"></i>
                                {car.transmission}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                {/* </Slider> */}
              </div>

              <div className="d-flex align-items-center justify-content-between py-2">
                <div className="d-flex align-items-center me-sm-4">
                  <label
                    className="fs-sm fw-normal text-nowrap mb-0 me-2 pe-1 form-label"
                    for="sortbyBottom"
                  >
                    <i className="fi-arrows-sort mt-n1 me-2"></i>Sort by:
                  </label>
                  <select
                    className="me-sm-4 form-select form-select-sm"
                    id="sortbyBottom"
                  >
                    <option value="newest">Newest</option>
                    <option value="popular">Popular</option>
                    <option value="priceLH">Price: Low - High</option>
                    <option value="priceHL">Price: High - Low</option>
                  </select>
                  <div
                    className="d-none d-md-block border-end border-light"
                    style={{ height: "1.25rem" }}
                  ></div>
                </div>

                <nav aria-label="Pagination">
                  <ul className="mb-0 pagination">
                    <li className="page-item active">
                      <span className="page-link">
                        1<span className="visually-hidden">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        tabindex="0"
                        href="#"
                      >
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        tabindex="0"
                        href="#"
                      >
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        tabindex="0"
                        href="#"
                      >
                        <span aria-hidden="true">…</span>
                        <span className="visually-hidden">More</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        tabindex="0"
                        href="#"
                      >
                        8
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        tabindex="0"
                        href="#"
                      >
                        <i className="fi-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CarListingPage;
