import React, { useState, useContext } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SignInImg from "../assets/img/signin-modal/sign-in-illustration.svg";
import { requestOtp, verifyOtp } from "../_services/authService";
import { AuthContext } from "../context/AuthContext";
import { WishlistContext } from "../context/WishlistContext";


import {
  addCarToWishlist,
  deleteCarFromWishlist,
} from "../_services/carsService";
import { ToastContext } from "../context/ToastContext";

const AddToWishListBtn = ({ car }) => {
  const { updateWishlist } = useContext(WishlistContext);
  const { isLoggedIn, login, fetchUserInfo } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const { showToast } = useContext(ToastContext);

  const handleFavorite = async () => {
    if (isLoggedIn) {
      if (car.is_fav) {
        try {
          await deleteCarFromWishlist(car.id);
          car.is_fav = false;
          updateWishlist()
          showToast("Removed from Wishlist!", "dark");
        } catch (err) {
          setError(err);
          showToast("Failed to update wishlist.", "danger");
        }
      } else {
        try {
          await addCarToWishlist(car.id);
          car.is_fav = true;
          updateWishlist()
          showToast("Added to Wishlist!", "dark");
        } catch (err) {
          setError(err);
          showToast("Failed to update wishlist.", "danger");
        }
      }
    } else {
      const loginEvent = new Event("openLoginModal");
      window.dispatchEvent(loginEvent); // Dispatch global event
      return;
    }
  };

  return (
    <button
      className="btn btn-icon btn-secondary btn-xs text-primary rounded-circle"
      type="button"
      onClick={(e) => {
        e.preventDefault();
        handleFavorite();
      }}
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      title="Add to Wishlist"
    >
      {!car.is_fav ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.4em"
          height="1.4em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.624 4.424C3.965 5.182 2.75 6.986 2.75 9.137c0 2.197.9 3.891 2.188 5.343c1.063 1.196 2.349 2.188 3.603 3.154q.448.345.885.688c.526.415.995.778 1.448 1.043s.816.385 1.126.385s.674-.12 1.126-.385c.453-.265.922-.628 1.448-1.043q.437-.344.885-.687c1.254-.968 2.54-1.959 3.603-3.155c1.289-1.452 2.188-3.146 2.188-5.343c0-2.15-1.215-3.955-2.874-4.713c-1.612-.737-3.778-.542-5.836 1.597a.75.75 0 0 1-1.08 0C9.402 3.882 7.236 3.687 5.624 4.424M12 4.46C9.688 2.39 7.099 2.1 5 3.059C2.786 4.074 1.25 6.426 1.25 9.138c0 2.665 1.11 4.699 2.567 6.339c1.166 1.313 2.593 2.412 3.854 3.382q.43.33.826.642c.513.404 1.063.834 1.62 1.16s1.193.59 1.883.59s1.326-.265 1.883-.59c.558-.326 1.107-.756 1.62-1.16q.396-.312.826-.642c1.26-.97 2.688-2.07 3.854-3.382c1.457-1.64 2.567-3.674 2.567-6.339c0-2.712-1.535-5.064-3.75-6.077c-2.099-.96-4.688-.67-7 1.399"
            clipRule="evenodd"
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.4em"
          height="1.4em"
          viewBox="0 0 24 24"
        >
          <path
            fill="#df0000"
            d="M2 9.137C2 14 6.02 16.591 8.962 18.911C10 19.729 11 20.5 12 20.5s2-.77 3.038-1.59C17.981 16.592 22 14 22 9.138S16.5.825 12 5.501C7.5.825 2 4.274 2 9.137"
          ></path>
        </svg>
      )}
    </button>
  );
};

export default AddToWishListBtn;
