import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SignInImg from "../assets/img/signin-modal/sign-in-illustration.svg";
import { requestOtp, verifyOtp } from "../_services/authService";
import { AuthContext } from "../context/AuthContext";
import { ToastContext } from "../context/ToastContext";

const LoginModal = ({ show, handleClose }) => {
  const { login, fetchUserInfo } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1: Enter Email, 2: Enter OTP
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showToast } = useContext(ToastContext);
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    const openLoginHandler = () => setIsVisible(true);
    window.addEventListener("openLoginModal", openLoginHandler);
    return () => window.removeEventListener("openLoginModal", openLoginHandler);
  }, []);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await requestOtp(email);
      setStep(2); // Move to OTP input
      showToast("OTP Sent to your Email!", "success");
    } catch (err) {
      // setError(err.message || "Failed to send OTP. Please try again.");
      showToast("Failed to send OTP. Please try again.", "danger");
    } finally {
      setLoading(false);
    }
  };


  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const token = await verifyOtp(email, otp);
      login(token);
      setIsVisible(false);
      setStep(1);
      showToast("Login successful!", "success");
      fetchUserInfo();
    } catch (err) {
      setStep(1);
      // setError(err.message || "Invalid OTP. Please try again.");
      showToast("Invalid OTP. Please try again.", "danger");
    } finally {
      setLoading(false);
      setEmail("");
      setOtp("");
    }
  };

  return (
    <Modal
      size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Modal.Body className="p-0 bg-gray rounded overflow-hidden">
        <button
          onClick={() => {
            setIsVisible(false);
          }}
          className="btn-close position-absolute top-0 end-0 mt-3 me-3"
          type="button"
        ></button>
        <div className="row mx-0 align-items-center">
          <div className="col-md-6 p-4 p-sm-5">
            <h2 className="h3 mb-4 mb-sm-5 text-primary">
              Welcome to
              <br />
              XLR8
            </h2>
            <img
              className="d-block mx-auto"
              src={SignInImg}
              width="344"
              alt="Illustration"
            />
          </div>
          <div className="col-md-6 bg-light border-start px-4 pt-2 pb-4 px-sm-5 py-sm-5">
            <div className="mt-4">
              {step === 1 ? (
                <Form onSubmit={handleSendOtp}>
                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {error && <p className="text-danger mt-3">{error}</p>}
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send OTP"}
                  </Button>
                </Form>
              ) : (
                <Form onSubmit={handleVerifyOtp}>
                  <Form.Group controlId="formOtp">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the OTP sent to your email"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {error && <p className="text-danger mt-3">{error}</p>}
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 w-100"
                    disabled={loading}
                  >
                    {loading ? "Verifying..." : "Verify OTP"}
                  </Button>
                </Form>
              )}
            </div>
            <div className="d-flex align-items-center py-3 my-4">
              <hr className="w-100" />
              <div className="opacity-70 px-3">Or</div>
              <hr className="w-100" />
            </div>
            <a className="btn btn-outline-info w-100 mb-3" href="#">
              <i className="fi-google fs-lg me-1"></i>Sign in with Google
            </a>
            <a className="btn btn-outline-info w-100 mb-3" href="#">
              <i className="fi-facebook fs-lg me-1"></i>Sign in with Facebook
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
