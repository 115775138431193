import React, { createContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("success");

  const showToast = (msg, type = "success") => {
    setMessage(msg);
    setVariant(type);
    setShow(true);
    setTimeout(() => setShow(false), 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}

      {/* Global Toast */}
      <ToastContainer
        position="bottom-center"
        className="p-3"
        style={{ zIndex: 9999, position:'fixed' }}
      >
        <Toast style={{width:'auto !important'}} bg={variant} show={show} onClose={() => setShow(false)}>
          <Toast.Body className="text-white text-center">{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
};
