import React, { useState, useContext } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SignInImg from "../assets/img/signin-modal/sign-in-illustration.svg";
import { requestOtp, verifyOtp } from "../_services/authService";
import { AuthContext } from "../context/AuthContext";
import {
  addCarToWishlist,
  deleteCarFromWishlist,
} from "../_services/carsService";
import { ToastContext } from "../context/ToastContext";

const RemoveFromWishListBtn = ({ car, removeFromWishList }) => {
  const { isLoggedIn, login, fetchUserInfo } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const { showToast } = useContext(ToastContext);

  // const removeFromWishList = async () => {
  //   try {
  //     await deleteCarFromWishlist(car.id);
  //     car.is_fav = false;
  //     showToast("Removed from Wishlist!", "dark");
  //   } catch (err) {
  //     setError(err);
  //     showToast("Failed to update wishlist.", "danger");
  //   }
  // };

  return (
    <button
      className="btn btn-icon btn-secondary btn-xs text-primary rounded-circle"
      type="button"
      onClick={(e) => {
        e.preventDefault();
        removeFromWishList(car.id);
      }}
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      title="Add to Wishlist"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12 2.75a2.25 2.25 0 0 0-2.122 1.5a.75.75 0 0 1-1.414-.5a3.751 3.751 0 0 1 7.073 0a.75.75 0 0 1-1.415.5A2.25 2.25 0 0 0 12 2.75M2.75 6a.75.75 0 0 1 .75-.75h17a.75.75 0 0 1 0 1.5h-17A.75.75 0 0 1 2.75 6m3.165 2.45a.75.75 0 1 0-1.497.1l.464 6.952c.085 1.282.154 2.318.316 3.132c.169.845.455 1.551 1.047 2.104s1.315.793 2.17.904c.822.108 1.86.108 3.146.108h.879c1.285 0 2.324 0 3.146-.108c.854-.111 1.578-.35 2.17-.904c.591-.553.877-1.26 1.046-2.104c.162-.814.23-1.85.316-3.132l.464-6.952a.75.75 0 0 0-1.497-.1l-.46 6.9c-.09 1.347-.154 2.285-.294 2.99c-.137.685-.327 1.047-.6 1.303c-.274.256-.648.422-1.34.512c-.713.093-1.653.095-3.004.095h-.774c-1.35 0-2.29-.002-3.004-.095c-.692-.09-1.066-.256-1.34-.512c-.273-.256-.463-.618-.6-1.303c-.14-.705-.204-1.643-.294-2.99z"
        ></path>
        <path
          fill="currentColor"
          d="M9.425 10.254a.75.75 0 0 1 .821.671l.5 5a.75.75 0 0 1-1.492.15l-.5-5a.75.75 0 0 1 .671-.821m5.821.821a.75.75 0 0 0-1.492-.15l-.5 5a.75.75 0 0 0 1.492.15z"
        ></path>
      </svg>
    </button>
  );
};

export default RemoveFromWishListBtn;
