import axiosInstance from "../_utils/axiosInstance";

// Request OTP
export const requestOtp = async (email) => {
  try {
    const response = await axiosInstance.post("/otp/generate", { email });
    return response.data.message; // e.g., "OTP sent successfully"
  } catch (error) {
    console.error("Error requesting OTP:", error.response?.data || error.message);
    throw error.response?.data?.message || "Failed to request OTP.";
  }
};

// Verify OTP
export const verifyOtp = async (email, otp) => {
  try {
    const response = await axiosInstance.post("/otp/verify_otp", { email, otp });
    return response.data.access_token; // JWT token
  } catch (error) {
    console.error("Error verifying OTP:", error.response?.data || error.message);
    throw error.response?.data?.message || "Invalid OTP. Please try again.";
  }
};