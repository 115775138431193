import LatestCars from "../homepage/components/LatestCars";
import LatestNews from "../homepage/components/LatestNews";
import TopOffers from "../homepage/components/TopOffers";

import React, { useContext, useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../context/ToastContext";
import { uploadCar } from "../../_services/carsService";

// Dropdown options
const carMakes = [
  "Toyota",
  "Honda",
  "Ford",
  "BMW",
  "Mercedes",
  "Audi",
  "Nissan",
  "Hyundai",
  "Kia",
  "Tesla",
];
const bodyTypes = [
  "Sedan",
  "SUV",
  "Hatchback",
  "Convertible",
  "Truck",
  "Van",
  "Coupe",
];
const fuelTypes = ["Petrol", "Diesel", "Electric", "Hybrid"];
const transmissions = ["Manual", "Automatic", "CVT"];
const drivetrains = ["FWD", "RWD", "AWD", "4WD"];
const featuresList = [
  "Sunroof",
  "Leather Seats",
  "Backup Camera",
  "Bluetooth",
  "Cruise Control",
  "Navigation",
  "Heated Seats",
];

const SellCarPage = ({ }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [videoPreview, setVideoPreview] = useState(null);
  const { showToast } = useContext(ToastContext);

  const initialValues = {
    make: "",
    model: "",
    year: "",
    vin: "",
    price: "",
    odometer_reading: "",
    body_type: "",
    fuel_type: "",
    engine: "",
    transmission: "",
    drivetrain: "",
    color: "",
    quoted_price: "",
    features: "",
    images: [],
    video: "",
    user_name: "",
    user_email: "",
    user_phone_number: "",
    user_address: "",
  };

  const validationSchema = Yup.object({
    make: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    year: Yup.number()
      .required("Required")
      .min(1900)
      .max(new Date().getFullYear()),
    vin: Yup.string().required("Required"),
    price: Yup.number().required("Required"),
    odometer_reading: Yup.number().required("Required"),
    body_type: Yup.string().required("Required"),
    fuel_type: Yup.string().required("Required"),
    engine: Yup.string().required("Required"),
    transmission: Yup.string().required("Required"),
    drivetrain: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
    quoted_price: Yup.number().required("Required"),
    features: Yup.string().required("Required"),
    images: Yup.mixed().required("Required"),
    video: Yup.mixed(),
    user_name: Yup.string().required("Required"),
    user_email: Yup.string().email("Invalid email").required("Required"),
    user_phone_number: Yup.string().required("Required"),
    user_address: Yup.string().required("Required"),
  });

  const handleFeatureChange = (event, selectedFeatures, setFieldValue) => {
    const { checked, value } = event.target;

    let updatedFeatures = [...selectedFeatures]; // Clone the existing selected features

    if (checked) {
      updatedFeatures.push(value);
    } else {
      updatedFeatures = updatedFeatures.filter((feature) => feature !== value);
    }

    setFieldValue("features", updatedFeatures.join(",")); // Convert to a comma-separated string
  };

  const handleImageChange = (event, setFieldValue) => {
    const files = Array.from(event.currentTarget.files);
    if (files.length) {
      setImagePreviews(files.map((file) => URL.createObjectURL(file)));
      setFieldValue("images", files);
    }
  };

  const handleVideoChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setVideoPreview(URL.createObjectURL(file));
      setFieldValue("video", file);
    }
  };

  const handleCarUpload = async (formData) => {
    console.log(formData);
    try {
      await uploadCar(formData);
      showToast("Car uploaded successfully!", "success");
    } catch (error) {
      showToast("Error uploading car", "danger");
    }
  };

  return (
    <div className="container mt-5 mb-md-4 py-5">
      <div className="row">
        {/* <!-- Page content--> */}
        <div className="col-lg-8">
          {/* <!-- Breadcrumb--> */}
          <nav className="mb-3 pt-md-3" aria-label="Breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sell car
              </li>
            </ol>
          </nav>
          {/* <!-- Title--> */}
          <div className="mb-4">
            <h1 className="h2  mb-0">Sell car</h1>
            <div className="d-lg-none  pt-3 mb-2">80% content filled</div>
            <div
              className="progress d-lg-none mb-4"
              style={{
                height: ".25rem",
              }}
            >
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow="80"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          {/* <!-- Basic info--> */}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // resetForm();
              const formData = new FormData();

              // Append all form fields
              Object.keys(values).forEach((key) => {
                if (key === "images") {
                  for (let i = 0; i < values.images.length; i++) {
                    formData.append("images", values.images[i]);
                  }
                } else if (key === "video") {
                  if (values.video) formData.append("video", values.video);
                } else {
                  formData.append(key, values[key]);
                }
              });

              handleCarUpload(formData);
              setSubmitting(false);
              resetForm();
            }}
          >
            {({ values, setFieldValue }) => (
              <FormikForm>
                <section
                  className="card card-body border-0 bg-gray p-4 mb-4"
                  id="basic-info"
                >
                  <Row>
                    {/* Car Make Dropdown */}
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Car Make</Form.Label>
                        <Field as="select" className="form-select" name="make">
                          <option value="">Select a car make</option>
                          {carMakes.map((make, index) => (
                            <option key={index} value={make}>
                              {make}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="make"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>

                    {/* Model */}
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Model</Form.Label>
                        <Field className="form-control" name="model" />
                        <ErrorMessage
                          name="model"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>

                    {/* Other Fields */}
                    {[
                      { name: "year", label: "Year", type: "number" },
                      { name: "vin", label: "VIN", type: "text" },
                      { name: "price", label: "Price", type: "number" },
                      {
                        name: "odometer_reading",
                        label: "Odometer Reading",
                        type: "number",
                      },
                      { name: "engine", label: "Engine", type: "text" },
                      { name: "color", label: "Color", type: "text" },
                      {
                        name: "quoted_price",
                        label: "Quoted Price",
                        type: "number",
                      },
                    ].map((field, index) => (
                      <Col md={6} key={index}>
                        <Form.Group className="mb-3">
                          <Form.Label>{field.label}</Form.Label>
                          <Field
                            className="form-control"
                            name={field.name}
                            type={field.type}
                          />
                          <ErrorMessage
                            name={field.name}
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    ))}

                    {/* Select Fields */}
                    {[
                      {
                        name: "body_type",
                        label: "Body Type",
                        options: bodyTypes,
                      },
                      {
                        name: "fuel_type",
                        label: "Fuel Type",
                        options: fuelTypes,
                      },
                      {
                        name: "transmission",
                        label: "Transmission",
                        options: transmissions,
                      },
                      {
                        name: "drivetrain",
                        label: "Drivetrain",
                        options: drivetrains,
                      },
                    ].map((field, index) => (
                      <Col md={6} key={index}>
                        <Form.Group className="mb-3">
                          <Form.Label>{field.label}</Form.Label>
                          <Field
                            as="select"
                            className="form-select"
                            name={field.name}
                          >
                            <option value="">Select {field.label}</option>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name={field.name}
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>
                </section>

                <section
                  className="card card-body border-0 bg-gray p-4 mb-4"
                  id="basic-info"
                >
                  {/* File Uploads */}
                  <Form.Group className="mb-3">
                    <Form.Label>Car Images</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(e) => handleImageChange(e, setFieldValue)}
                    />
                    {imagePreviews.map((src, idx) => (
                      <img
                        key={idx}
                        src={src}
                        alt="Preview"
                        className="img-thumbnail mx-2"
                        style={{ maxWidth: "100px" }}
                      />
                    ))}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Car Video</Form.Label>
                    <Form.Control
                      type="file"
                      accept="video/*"
                      onChange={(e) => handleVideoChange(e, setFieldValue)}
                    />
                    {videoPreview && (
                      <video controls width="100%" className="mt-2">
                        <source src={videoPreview} type="video/mp4" />
                      </video>
                    )}
                  </Form.Group>
                </section>

                <section
                  className="card border-0 bg-gray card-body p-4 mb-4"
                  id="features"
                >
                  <h2 className="h4  mb-4">
                    <i className="fi-check-circle text-primary fs-5 mt-n1 me-2"></i>
                    Features
                  </h2>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <div>
                          {featuresList.map((feature, index) => (
                            <div
                              key={index}
                              className="form-check form-check-inline"
                            >
                              <Field
                                id={feature}
                                type="checkbox"
                                name="features"
                                value={feature}
                                className="form-check-input"
                                checked={
                                  values.features
                                    ? values.features
                                      .split(",")
                                      .includes(feature)
                                    : false
                                }
                                onChange={(e) =>
                                  handleFeatureChange(
                                    e,
                                    values.features
                                      ? values.features.split(",")
                                      : [],
                                    setFieldValue
                                  )
                                }
                              />
                              <label htmlFor={feature} className="form-check-label">
                                {feature}
                              </label>
                            </div>
                          ))}
                        </div>
                        <ErrorMessage
                          name="features"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </section>

                <section
                  className="card border-0 bg-gray card-body p-4 mb-4"
                  id="features"
                >
                  <h2 className="h4  mb-4">
                    <i className="fi-check-circle text-primary fs-5 mt-n1 me-2"></i>
                    Contacts
                  </h2>
                  <Row>
                    {/* User Details */}
                    {[
                      { name: "user_name", label: "Full Name", type: "text" },
                      { name: "user_email", label: "Email", type: "email" },
                      {
                        name: "user_phone_number",
                        label: "Phone Number",
                        type: "text",
                      },
                      { name: "user_address", label: "Address", type: "text" },
                    ].map((field, index) => (
                      <Col md={6} key={index}>
                        <Form.Group className="mb-3">
                          <Form.Label>{field.label}</Form.Label>
                          <Field
                            className="form-control"
                            name={field.name}
                            type={field.type}
                          />
                          <ErrorMessage
                            name={field.name}
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>
                </section>

                {/* Submit */}
                <div className="d-sm-flex justify-content-between pt-2">
                  <a
                    className="btn btn-outline-light btn-lg d-block mb-3 mb-sm-2"
                    href="#preview-modal"
                    data-bs-toggle="modal"
                  >
                    <i className="fi-eye-on ms-n1 me-2"></i>Preview
                  </a>
                  <Button type="submit" variant="primary" className="">
                    Submit
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
        {/* <!-- Progress of completion--> */}
        <aside className="col-lg-3 offset-lg-1 d-none d-lg-block">
          <div className="sticky-top pt-5">
            <h6 className=" pt-5 mt-3 mb-2">80% content filled</h6>
            <div
              className="progress progress-light mb-4"
              style={{ height: ".25rem" }}
            >
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow="80"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center">
                <i className="fi-check text-primary me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#basic-info"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Basic info
                </a>
              </li>
              <li className="d-flex align-items-center">
                <i className="fi-check text-primary me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#price"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Price
                </a>
              </li>
              <li className="d-flex align-items-center">
                <i className="fi-check text-primary me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#vehicle-info"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Vehicle information
                </a>
              </li>
              <li className="d-flex align-items-center">
                <i className="fi-check  opacity-60 me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#features"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Features
                </a>
              </li>
              <li className="d-flex align-items-center">
                <i className="fi-check  opacity-60 me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#photos"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Photos / video
                </a>
              </li>
              <li className="d-flex align-items-center">
                <i className="fi-check text-primary me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#location"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Location
                </a>
              </li>
              <li className="d-flex align-items-center">
                <i className="fi-check text-primary me-2"></i>
                <a
                  className="nav-link ps-1"
                  href="#contacts"
                  data-scroll
                  data-scroll-offset="20"
                >
                  Contacts
                </a>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default SellCarPage;
