import HeroImg from "../../../assets/img/car-finder/home/xlrb_bg.jpg";
import { fetchCars } from "../../../_services/carsService";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ToastContext } from "../../../context/ToastContext";
import { Link } from "react-router-dom";
function HeroSearch() {
  const { isLoggedIn } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [cars, setCars] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    const getCars = async () => {
      try {
        const data = await fetchCars();
        setData(data);
      } catch (err) {
        showToast("Failed to load cars.", "danger");
      }
    };

    getCars();
  }, [isLoggedIn]);
  useEffect(() => {
    setCars(data)
    console.log(data);

  }, [data])
  useEffect(() => {
    let temp = data.filter((dt) => {
      const q = dt.make.toLowerCase() + dt.model.toLowerCase();
      if (q.includes(searchQuery.replace(" ", "")))
        return dt;
    })
    setCars(temp)
  }, [searchQuery])
  return (
    <>
      <section
        className="bg-position-top-center bg-dark bg-repeat-0 pt-5 position-relative"
        style={{
          backgroundImage: `url(${HeroImg})`,
          backgroundSize: "1920px 730px",
        }}
      >
        <div className="container p-5 my-4">
          <div className="row pt-lg-4 pt-xl-5 justify-content-center">
            <div className="col-md-6 pt-3 pt-md-4 pt-lg-5 text-center">
              <h1 className="display-2 text-light pb-2 mb-4 me-md-n5">
                Easy way to find the right car
              </h1>
              <p className="fs-lg opacity-70 text-light">
                Xlr8 is a leading digital marketplace for the automotive
                industry that connects car shoppers with sellers.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-4 mt-sm-3 mt-lg-n3 pb-5 mb-md-4">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form className="form-group d-block">
                <div className="row g-0 ms-lg-n2">
                  <div className="input-group border-end-lg">
                    <span className="input-group-text text-muted ps-2 ps-sm-3">
                      <i className="fi-search"></i>
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(event) => {
                        setSearchQuery(event.target.value);
                      }}
                      name="keywords"
                      placeholder="Search Cars.. eg. Audi A4"
                    />
                    {searchQuery.length > 0 && <div className="" style={{ position: "absolute", background: "white", zIndex: "99", width: "100%", transform: "translateY(50px)", marginLeft: "5px", borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px' }}>
                      {cars.length > 0 ?
                        cars.map((c) => {
                          return <div style={{ padding: '10px', cursor: "pointer" }}><Link className="text-decoration-none" to={`/auctions/${c.id}`}>{c.make} {c.model}</Link></div>
                        })
                        : <div style={{ padding: "10px" }}>No cars found...</div>}
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroSearch;
