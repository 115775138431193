import React, { createContext, useState, useEffect } from 'react';

export const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
    const [wishlistCount, setWishlistCount] = useState(0); // Initial value (could be fetched from API or localStorage)

    // Function to update the wishlist count globally
    const updateWishlist = () => {
        setWishlistCount(wishlistCount + 1);
    };

    return (
        <WishlistContext.Provider value={{ wishlistCount, updateWishlist }}>
            {children}
        </WishlistContext.Provider>
    );
};
