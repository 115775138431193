import Avatar from "../../assets/img/avatars/01.jpg";
import React, { useEffect, useState } from "react";
import { fetchCurrentUserInfo } from "../../_services/userService";

function PersonalInfo() {
  const [userInfo, setUserInfo] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await fetchCurrentUserInfo();
        setUserInfo({
          fullName: data.name || "Not specified",
          email: data.email || "Not specified",
          phoneNumber: data.phone_number || "Not specified",
          address: data.address || "Not specified",
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, []);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container pb-lg-4 mb-sm-2">
      {/* <!-- Breadcrumb--> */}
      <nav className="mb-4 pt-md-3" aria-label="Breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li className="breadcrumb-item">
            <a href="#">Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Personal Info
          </li>
        </ol>
      </nav>
      {/* <!-- Page content--> */}
      <div className="row">
        {/* <!-- Content--> */}
        <div className="col-lg-8 col-md-7 mb-5">
          <h1 className="h2">Personal Info</h1>
          <div className="mb-2 pt-1">Your personal info is 50% completed</div>
          <div className="progress mb-4" style={{ height: ".25rem" }}>
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: "50%" }}
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="row pt-2">
            <div className="col-lg-9 col-md-12 col-sm-8 mb-2 mb-m-4">
              <div className="border rounded-3 p-3 mb-4" id="personal-info">
                {/* <!-- Name--> */}
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2 opacity-70">
                      <label className="form-label fw-bold">Full name</label>
                      <div id="name-value">{userInfo.fullName}</div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#name-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="name-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <input
                      className="form-control mt-3"
                      type="text"
                      data-bs-binded-element="#name-value"
                      data-bs-unset-value="Not specified"
                      value="Robert Fox"
                    />
                  </div>
                </div>
                {/* <!-- Gender--> */}
                {/* <div className="border-bottom  pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2 opacity-70">
                      <label className="form-label fw-bold">Gender</label>
                      <div id="gender-value">Not specified</div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#gender-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="gender-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <select
                      className="form-select mt-3"
                      data-bs-binded-element="#gender-value"
                    >
                      <option value="" disabled selected>
                        Select your gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div> */}
                {/* <!-- Email--> */}
                <div className="border-bottom  pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2 opacity-70">
                      <label className="form-label fw-bold">
                        Email
                      </label>
                      <div id="email-value">
                        {userInfo.email}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#email-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="email-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <input
                      className="form-control form-control-light mt-3"
                      type="email"
                      data-bs-binded-element="#email-value"
                      data-bs-unset-value="Not specified"
                      value="robert_fox@email.com"
                    />
                  </div>
                </div>
                {/* <!-- Phone number--> */}
                <div className="border-bottom  pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2 opacity-70">
                      <label className="form-label fw-bold">
                        Phone number
                      </label>
                      <div id="phone-value">
                        {userInfo.email}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#phone-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="phone-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <input
                      className="form-control mt-3"
                      type="text"
                      data-bs-binded-element="#phone-value"
                      data-bs-unset-value="Not specified"
                      value="(302) 555-0107"
                    />
                  </div>
                </div>
                {/* <!-- Address--> */}
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2 opacity-70">
                      <label className="form-label fw-bold">
                        Address
                      </label>
                      <div id="address-value">
                        {userInfo.address}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#address-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="address-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <input
                      className="form-control mt-3"
                      type="text"
                      data-bs-binded-element="#address-value"
                      data-bs-unset-value="Not specified"
                      placeholder="Enter address"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-4 mb-4">
              <input
                className="file-uploader  bg-faded-light"
                type="file"
                accept="image/png, image/jpeg"
                data-label-idle='&lt;i className="d-inline-block fi-camera-plus fs-2 text-light text-muted mb-2"&gt;&lt;/i&gt;&lt;br&gt;&lt;span className="fw-bold text-light opacity-70"&gt;Change picture&lt;/span&gt;'
                data-style-panel-layout="compact"
                data-image-preview-height="160"
                data-image-crop-aspect-ratio="1:1"
                data-image-resize-target-width="200"
                data-image-resize-target-height="200"
              />
              <div className="pt-4 pb-2 py-lg-5">
                <h6 className="mb-2">Who can see my profile?</h6>
                <p className="fs-sm opacity-70 mb-0">
                  Your profile is private. No one else will see it untill a
                  reservation is confirmed.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Action buttons--> */}
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <button className="btn btn-primary" type="button">
                  Save changes
                </button>
                <button className="btn btn-link btn-sm px-0" type="button">
                  <i className="fi-trash me-2"></i>Delete account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
