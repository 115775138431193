import Catalog8 from "../../../assets/img/car-finder/catalog/08.jpg";
import Catalog4 from "../../../assets/img/car-finder/catalog/04.jpg";
import { useContext, useEffect, useRef, useState } from "react";
import { fetchCars, fetchLatestCars } from "../../../_services/carsService";
import { Link } from "react-router-dom";
import AddToWishListBtn from "../../../components/addToWishListBtn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "../../../components/ImageSlider.jsx";
import { AuthContext } from "../../../context/AuthContext.js";

function LatestCars() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  useEffect(() => {
    const getCars = async () => {
      try {
        const data = await fetchLatestCars();
        setCars(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getCars();

  }, [isLoggedIn]);


  const sliderSettings = {
    infinite: false,
    slidesToShow: 4,
    // arrows: true,
    className: "slides",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For mobile devices (e.g., <=768px width)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // For tablets (optional)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // Larger screens (e.g., desktops, >= 1200px width)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  }


  return (
    <section className="bg-gray py-5">
      <div className="container mb-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-3 mb-sm-4 pb-2">
          <h2 className="h3 mb-3 mb-sm-0">Latest Auctions</h2>
          <div className="d-flex align-items-center">
            <Link to="/auctions" className="btn btn-link fw-normal px-0">
              View all Auctions
              <i className="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i>
            </Link>
          </div>
        </div>
        <div className="row content">
          <Slider {...sliderSettings}>
            {cars.map((car, index) => (
              <div key={index} className="col-lg-3">
                <Link key={index} className="text-decoration-none" to={`/auctions/${car.id}`}>
                  <div className="card card-hover">
                    <ImageSlider car={car} />
                    <div className="card-body px-0">
                      <div className="d-flex px-3 align-items-center justify-content-between pb-1">
                        <div className="text-primary fw-bold mb-1">
                          ${car.current_bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <span className="fs-sm me-3">
                          {car.odometer_reading >= 1e6 ? (car.odometer_reading / 1e6).toFixed(1) + 'M' : car.odometer_reading >= 1e3 ? (car.odometer_reading / 1e3).toFixed(1) + 'K' : car.odometer_reading.toString()} mi
                        </span>
                      </div>
                      <h3 className="h6 mb-1 px-3">
                        <a className="nav-link">
                          {car.year} {car.make} {car.model}
                        </a>
                      </h3>
                      {/* <div className="fs-sm text-muted px-3">
                        <i className="fi-map-pin me-1"></i> Boston, 560068
                      </div> */}
                    </div>

                    <div className="card-footer px-0 bg-transparent border-0 pt-0 pb-3">
                      <div className="border-top pt-3 px-3">
                        <div className="row row-cols-2 g-2 fs-sm">
                          <div className="col d-flex align-items-center gap-2">
                            <i className="fi-petrol"></i>
                            {car.fuel_type}
                          </div>
                          <div className="col d-flex align-items-center gap-2">
                            <i className="fi-gearbox"></i>
                            {car.transmission}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default LatestCars;
