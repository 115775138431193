import React, { useState, useContext, useEffect } from "react";
import { Button, Form, FormGroup, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SignInImg from "../assets/img/signin-modal/sign-in-illustration.svg";
import { requestOtp, verifyOtp } from "../_services/authService";
import { AuthContext } from "../context/AuthContext";
import { ToastContext } from "../context/ToastContext";
import { placeCarBid, getCarBid, updateCarBid } from "../_services/carsService";

const PlaceBidModal = ({ car, show, handleClose, bidStatus, setBidStatus, currentBid, setCurrentBid, fetchCar }) => {
  const { isLoggedIn, login, fetchUserInfo } = useContext(AuthContext);
  const [amount, setAmount] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1: Enter Email, 2: Enter OTP
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showToast } = useContext(ToastContext);
  const [isVisible, setIsVisible] = useState(show);
  const bidsRetrieve = async () => {
    // console.log(car.id);

    let bids = await getCarBid();
    bids.data.forEach((bid) => {
      if (bid.car_id === car.id) {
        setBidStatus(true);
        setCurrentBid(bid);
        setAmount(bid.bid_amount);
      }

    })
  }
  useEffect(() => {
    if (isLoggedIn)
      bidsRetrieve();
    else {
      setBidStatus(false);
    }
    const openLoginHandler = () => setIsVisible(true);
    window.addEventListener("openLoginModal", openLoginHandler);
    return () => window.removeEventListener("openLoginModal", openLoginHandler);
  }, [isLoggedIn]);

  const handlePlaceBid = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        bid_amount: parseFloat(amount),
        car_id: car.id,
      };
      if (bidStatus) {
        await updateCarBid(payload);
      } else
        await placeCarBid(payload);
      setBidStatus(true);
      setCurrentBid((prev) => {
        return {
          ...prev,
          bid_amount: amount
        }

      })
      setAmount(amount);
      fetchCar();
      showToast(`Bid Placed  for amount ${amount} Successfully!`, "success");
      handleClose();
    } catch (err) {
      // setError(err.message || "Failed to send OTP. Please try again.");
      showToast(err, "danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Body className="p-0 bg-gray rounded overflow-hidden">
        <button
          onClick={handleClose}
          className="btn-close position-absolute top-0 end-0 mt-3 me-3"
          type="button"
        ></button>
        <div className="row mx-0 align-items-center">
          <div className="col bg-light border-start px-0 pt-2 pb-4 py-sm-5">
            <div className="mt-4">
              <div className="px-4 px-md-5">
                <h2 className="h3 text-primary">Place Bid</h2>
                <h3 className="h4">
                  {car.year} {car.make} {car.model}
                </h3>
                <p>
                  We require a valid credit card on file before you can bid.
                  Winning bidders pay a 4.5% buyer’s fee to Cars & Bids on top
                  of the winning bid amount. The minimum buyer’s fee is $225,
                  and the maximum is $4,500.
                </p>
              </div>
              <hr className="mt-3 mb-4" />
              <p className="px-4 px-md-5 text-dark">
                Bids are binding, so please bid wisely!
              </p>
              <div className="px-4 px-md-5">
                <Form onSubmit={handlePlaceBid}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter your amount"
                      className="form-control-lg"
                      style={{ fontSize: "22px", fontWeight: 600 }}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </InputGroup>

                  {error && <p className="text-danger mt-3">{error}</p>}
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100"
                    disabled={loading}
                  >
                    {loading ? "Connecting..." : (bidStatus ? "Modify Bid" : "Submit Bid")}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PlaceBidModal;
