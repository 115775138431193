import BodyTypes from "./components/BodyTypes";
import Brands from "./components/Brands";
import Features from "./components/Features";
import HeroSearch from "./components/HeroSearch";
import LatestCars from "./components/LatestCars";
import LatestNews from "./components/LatestNews";
import TopOffers from "./components/TopOffers";

function HomePage() {
  return (
    <>
      <HeroSearch />
      {/* <BodyTypes /> */}
      <TopOffers />
      {/* <Brands /> */}
      <LatestCars />
      <Features />
      <LatestNews />
    </>
  );
}

export default HomePage;
