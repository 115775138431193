// src/_services/userService.js
import axiosInstance from "../_utils/axiosInstance";
export const fetchCurrentUserInfo = async () => {
    try {
        const token = localStorage.getItem("token");
        const response = await axiosInstance.get(`/users/myinfo?authorization=${`Bearer ${token}`}`);
        return response.data;
    } catch (error) {
        const errorMessage =
            error.response?.data?.message || "Failed to fetch user information";
        console.error("Error fetching user info:", errorMessage);
        throw errorMessage;
    }
};