import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoginModal from "../components/LoginModal";
import { Outlet } from "react-router-dom";

const DefaultLayout = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginClick = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1"><Outlet /></main>
      <Footer />
      {/* Login Modal */}
      <LoginModal
        show={showLoginModal}
        handleClose={handleCloseLoginModal}
        handleSubmit={(e) => {
          e.preventDefault();
          console.log("Login Submitted!");
        }}
      />
    </div>
  );
};

export default DefaultLayout;
