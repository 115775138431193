import React, { useRef } from "react";
import Slider from "react-slick";
import AddToWishListBtn from "./addToWishListBtn";

const ImageSlider = ({ car, add = "yes" }) => {
  // console.log(car.model + " " + car.images.length);

  const next = () => {
    sliderRef.current.slickNext();
  };
  const sliderRef = useRef(null);
  const imageSliderSettings = {
    infinite: true,
    slidesToShow: 1,
    className: "slides",
    // style: { width: "100%", padding: "0" },
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    pauseOnHover: false,
  };
  const ImageMapper = (image, id, car, single = false) => {

    // image wil conssit of the url only hence no formatting is required
    const url = image.image_url;


    return (
      <div
        key={id}
        style={{ width: "100%" }}
        className="card-img-top card-img-hover">
        <a className="img-overlay" href="#"></a>
        {/* <div className="position-absolute start-0 top-0 pt-3 ps-3">
          <span className="d-table badge bg-info" style={{ zIndex: 5 }}>
            New
          </span>
        </div> */}
        {add === "yes" && (
          <div className="content-overlay end-0 top-0 pt-3 pe-3">
            <AddToWishListBtn car={car}></AddToWishListBtn>
          </div>
        )}
        <div>
          <img
            style={{ height: "250px", width: "100%" }}
            src={image.image_url}
            alt="Image1"
            onMouseEnter={single ? () => { } : next}
          />
        </div>
      </div>
    );
  };
  if (car.images.length > 1)
    return (
      <Slider ref={sliderRef} {...imageSliderSettings}>
        {car.images.map((image, id) => {
          return ImageMapper(image, id, car);
        })}
      </Slider>
    );
  else
    return (
      <div>
        {car.images.map((image, id) => {
          return ImageMapper(image, id, car, true);
        })}
      </div>
    );
};

export default ImageSlider;
