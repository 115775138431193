import Catalog17 from "../../assets/img/car-finder/catalog/17.jpg";
import Catalog09 from "../../assets/img/car-finder/catalog/09.jpg";
import { useContext, useEffect, useState } from "react";
import {
  addCarToWishlist,
  fetchCardetails,
  fetchCars
} from "../../_services/carsService";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Alert, Card, Spinner } from "react-bootstrap";
import Avatar from "../../assets/img/avatars/01.jpg";
import AddToWishListBtn from "../../components/addToWishListBtn";
import { AuthContext } from "../../context/AuthContext";
import { ToastContext } from "../../context/ToastContext";
import PlaceBidModal from "../../components/PlaceBidModal";
import Slider from "react-slick";

const CarViewPage = () => {
  const { id } = useParams();
  const { isLoggedIn, login, userInfo } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const [car, setCar] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPlaceBidModal, setShowPlaceBidModal] = useState(false);
  const [bidStatus, setBidStatus] = useState(false);
  const [currentBid, setCurrentBid] = useState({});
  const [highestBid, setHighestBid] = useState(0);
  const handleCloseBidModal = () => setShowPlaceBidModal(false);

  const fetchCar = async () => {
    try {
      const data = await fetchCardetails(id);
      console.log(data);

      setCar(data);
    } catch (err) {
      setError(err);
      showToast("Failed to load Car details.", "danger");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(userInfo);

    fetchCar();
  }, [id]);

  const handlePlaceBid = async () => {
    if (isLoggedIn) {
      setShowPlaceBidModal(true);
    } else {
      const loginEvent = new Event("openLoginModal");
      window.dispatchEvent(loginEvent); // Dispatch global event
      return;
    }
  };

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    className: "slides",
    slidesToScroll: 1,
  }
  const ImageMapper = (image, id) => {
    // now the image will contains url and requires no formatting
    const url = image.image_url;


    return (
      <img
        key={id}
        src={image.image_url}
        alt="Image1"
      />
    );
  };

  return (
    <section>
      {car && car.id && <PlaceBidModal
        show={showPlaceBidModal}
        car={car}
        handleClose={handleCloseBidModal}
        handleSubmit={(e) => {
          e.preventDefault();
          console.log("Login Submitted!");
        }}
        bidStatus={bidStatus}
        setBidStatus={setBidStatus}
        currentBid={currentBid}
        setCurrentBid={setCurrentBid}
        fetchCar={fetchCar}
      />}

      {loading && (
        <div
          className="my-5 py-5 w-100 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <span className="loader"></span>
        </div>
      )}
      {error && <Alert variant="danger">{error}</Alert>}

      {car && car.id && (
        <div className="container py-5">
          <nav className="py-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to="/auctions">Auctions</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {car.make} {car.model}
              </li>
            </ol>
          </nav>

          <div className="d-flex justify-content-between gap-3 position-relative z-2 mb-3 mb-lg-4">
            <h2 className="mb-0">
              {car.year} {car.make} {car.model}
            </h2>
            <div className="d-flex gap-2">
              <AddToWishListBtn car={car}></AddToWishListBtn>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 pb-3 pb-sm-0 mb-4 mb-sm-5 mb-lg-0">
              <div className="rounded">
                <Slider {...sliderSettings}>
                  {car.images.map((image, id) => {
                    return ImageMapper(image, id);
                  })}
                </Slider>
                {/* <img src={`https://xlr8.sg/${car.images[0].image_url.split("/").slice(-3).join("/")}`} alt="Image" className="rounded" /> */}
              </div>

              <div className="mb-4 mt-3">
                <div className="d-flex align-items-center justify-content-between gap-3 pb-1">
                  <div className="d-flex flex-wrap gap-2 gap-sm-3 fs-sm text-nowrap">
                    {/* <div className="d-flex align-items-center gap-2 me-2">
                      <i className="fi-map-pin"></i>
                      Chicago
                    </div> */}
                    <div className="d-flex align-items-center gap-2 me-2">
                      <i className="fi-tachometer"></i>
                      {car.odometer_reading >= 1e6 ? (car.odometer_reading / 1e6).toFixed(1) + 'M' : car.odometer_reading >= 1e3 ? (car.odometer_reading / 1e3).toFixed(1) + 'K' : car.odometer_reading.toString()} mi
                    </div>
                    <div className="d-flex align-items-center gap-2 me-2">
                      <i className="fi-gas-pump"></i>
                      {car.fuel_type}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <i className="fi-gearbox"></i>
                      {car.transmission}
                    </div>
                  </div>

                  <div className="d-flex gap-2 mb-3">
                    <span className="badge bg-info d-inline-flex align-items-center">
                      2 Days Left
                      <i className="fi-shield ms-1"></i>
                    </span>
                    <span className="badge text-bg-warning"># {car.total_bid_count} Bid{car.total_bid_count === 1 ? "" : "s"}</span>
                  </div>
                </div>
                <div className="bg-body-tertiary px-4 py-3 card mt-2">
                  <div className="d-flex justify-content-between">
                    <div className="mb-0">
                      <span className="fw-bold text-muted">xlr8 Price</span>
                      <h4 className="h4 mb-0">${car.xlr8_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                    </div>
                    {bidStatus && <div className="mb-0">
                      <span className="fw-bold text-muted">Current Bid</span>
                      <h4 className="h4 mb-0">${currentBid ? currentBid.bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</h4>
                    </div>}
                    {car.current_bid_amount && <div className="mb-0">
                      <span className="fw-bold text-muted">Highest Bid</span>
                      <h4 className="h4 mb-0">${car.current_bid_amount ? car.current_bid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</h4>
                    </div>}
                    <div>
                      <a onClick={handlePlaceBid} className="btn btn-primary">
                        {bidStatus ? "Update Bid" : "Place Bid"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="h3 pt-5 mt-sm-2 my-lg-4">Specifications</h2>
              <div className="row row-cols-1 row-cols-sm-2 gy-2">
                <div className="col">
                  <ul className="list-unstyled text-body-secondary mt-n1 mb-0">
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Manufacturing year:
                      </span>
                      {car.year}
                    </li>
                    {/* <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Mileage:
                      </span>
                      60K miles
                    </li> */}
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Body type:
                      </span>
                      {car.body_type}
                    </li>
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Drive type:
                      </span>
                      {car.drivetrain}
                    </li>
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Engine:
                      </span>
                      {car.engine}
                    </li>
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Transmission:
                      </span>
                      {car.transmission}
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-unstyled text-body-secondary mt-n1 mb-0">
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Fuel type:
                      </span>
                      {car.fuel_type}
                    </li>
                    {/* <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        City MPG:
                      </span>
                      60K miles
                      <i
                        className="fi-alert-circle fs-lg text-primary align-middle ms-2"
                        data-bs-toggle="tooltip"
                        title="Verified by seller"
                      ></i>
                    </li> */}
                    {/* <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Highway MPG:
                      </span>
                      Convertible
                      <i
                        className="fi-alert-circle fs-lg text-primary align-middle ms-2"
                        data-bs-toggle="tooltip"
                        title="Verified by seller"
                      ></i>
                    </li> */}
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Exterior color:
                      </span>
                      {car.color}
                    </li>
                    {/* <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        Interior color:
                      </span>
                      Charcoal
                    </li> */}
                    <li className="mt-1">
                      <span className="fw-medium text-dark-emphasis me-1">
                        VIN:
                      </span>
                      {car.vin}
                    </li>
                  </ul>
                </div>
              </div>

              <h2 className="h3 pt-5 mt-sm-2 mb-2 mb-lg-3">Features</h2>
              <div className="accordion accordion-alt-icon" id="features">
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingExterior">
                    <button
                      type="button"
                      className="accordion-button fs-5 collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#exterior"
                      aria-expanded="false"
                      aria-controls="exterior"
                    >
                      <span className="hover-effect-underline stretched-link me-2">
                        Exterior
                      </span>
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="exterior"
                    aria-labelledby="headingExterior"
                    data-bs-parent="#features"
                  >
                    <div className="accordion-body fs-base">
                      <ul className="mt-n1 mb-0">
                        <li className="mt-1">Alloy Wheels</li>
                        <li className="mt-1">Sunroof / Moonroof</li>
                        <li className="mt-1">Tinged glass</li>
                        <li className="mt-1">LED Headlights</li>
                        <li className="mt-1">Foldable Roof</li>
                        <li className="mt-1">Tow Hitch</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingInterior">
                    <button
                      type="button"
                      className="accordion-button fs-5"
                      data-bs-toggle="collapse"
                      data-bs-target="#interior"
                      aria-expanded="true"
                      aria-controls="interior"
                    >
                      <span className="hover-effect-underline stretched-link me-2">
                        Interior
                      </span>
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse show"
                    id="interior"
                    aria-labelledby="headingInterior"
                    data-bs-parent="#features"
                  >
                    <div className="accordion-body fs-base">
                      <div className="row row-cols-1 row-cols-sm-2 gy-2">
                        <div className="col">
                          <ul className="mt-n1 mb-0">
                            {car.features.slice(0, Math.ceil(car.features.length / 2)).map((ft, index) => (
                              <li className="mt-1" key={index}>{ft.name}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="col">
                          <ul className="mt-n1 mb-0">
                            {car.features.slice(Math.ceil(car.features.length / 2)).map((ft, index) => (
                              <li className="mt-1" key={index}>{ft.name}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingSafety">
                    <button
                      type="button"
                      className="accordion-button fs-5 collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#safety"
                      aria-expanded="false"
                      aria-controls="safety"
                    >
                      <span className="hover-effect-underline stretched-link me-2">
                        Safety
                      </span>
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="safety"
                    aria-labelledby="headingSafety"
                    data-bs-parent="#features"
                  >
                    <div className="accordion-body fs-base">
                      <div className="row row-cols-1 row-cols-sm-2 gy-2">
                        <div className="col">
                          <ul className="mt-n1 mb-0">
                            <li className="mt-1">Airbag: Driver</li>
                            <li className="mt-1">Airbag: Passenger</li>
                            <li className="mt-1">Adaptive Cruise Control</li>
                            <li className="mt-1">Blind Spot Monitor</li>
                            <li className="mt-1">Alarm</li>
                            <li className="mt-1">Antilock Brakes</li>
                          </ul>
                        </div>
                        <div className="col">
                          <ul className="mt-n1 mb-0">
                            <li className="mt-1">Brake Assist</li>
                            <li className="mt-1">Lane Departure Warning</li>
                            <li className="mt-1">Stability Control</li>
                            <li className="mt-1">Fog Lights</li>
                            <li className="mt-1">Power Door Locks</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTechnology">
                    <button
                      type="button"
                      className="accordion-button fs-5 collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#technology"
                      aria-expanded="false"
                      aria-controls="technology"
                    >
                      <span className="hover-effect-underline stretched-link me-2">
                        Technology
                      </span>
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="technology"
                    aria-labelledby="headingTechnology"
                    data-bs-parent="#features"
                  >
                    <div className="accordion-body fs-base">
                      <div className="row row-cols-1 row-cols-sm-2 gy-2">
                        <div className="col">
                          <ul className="mt-n1 mb-0">
                            <li className="mt-1">Multi-Zone A/C</li>
                            <li className="mt-1">Climate Control</li>
                            <li className="mt-1">Navigation System</li>
                            <li className="mt-1">Remote Start</li>
                            <li className="mt-1">Bluetooth</li>
                            <li className="mt-1">Remote Start</li>
                          </ul>
                        </div>
                        <div className="col">
                          <ul className="mt-n1 mb-0">
                            <li className="mt-1">Apple CarPlay</li>
                            <li className="mt-1">Android Auto</li>
                            <li className="mt-1">Backup Camera</li>
                            <li className="mt-1">HomeLink</li>
                            <li className="mt-1">Keyless Start</li>
                            <li className="mt-1">Premium Sound System</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="h3 pt-5 mt-sm-2">Seller's description</h2>
              <p>
                This stunning convertible blends luxury with performance,
                featuring a sleek design, advanced technology, and a powerful
                engine. Enjoy open-air driving with premium comfort and the
                unmistakable elegance of Mercedes-Benz. Impeccably maintained
                and ready to provide an exhilarating driving experience. Don't
                miss out on this exceptional vehicle.
              </p>
            </div>

            {/* <!-- Sidebar with car detail and seller info --> */}
            <aside className="col-lg-4" style={{ marginTop: "-110px" }}>
              <div
                className="position-sticky top-0"
                style={{ paddingTop: "110px" }}
              >
                {/* <!-- Seller info card --> */}
                <div className="card bg-body-tertiary border-0 p-sm-2 p-lg-0 p-xl-2 mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center position-relative mb-3">
                      <div
                        className="ratio ratio-1x1 flex-shrink-0 bg-body-secondary rounded-circle overflow-hidden"
                        style={{ width: "72px" }}
                      >
                        <img src={Avatar} alt="Avatar" />
                      </div>
                      <div className="w-100 ps-3">
                        <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                          <a
                            className="h6 fs-sm hover-effect-underline stretched-link text-decoration-none mb-0"
                            href="#!"
                          >
                            Darrell Steward
                          </a>
                          {/* <span className="badge text-bg-light">
                          Private seller
                        </span> */}
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <i className="fi-star-filled text-warning"></i>
                          <span className="fs-sm fw-medium text-dark-emphasis">
                            4.9
                          </span>
                          <span className="fs-xs text-body-secondary">
                            (5 reviews)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="nav mb-3">
                      <a className="nav-link position-relative px-0" href="#!">
                        <span className="hover-effect-underline stretched-link">
                          Other ads by this seller
                        </span>
                        <i className="fi-chevron-right fs-base ms-1"></i>
                      </a>
                    </div>
                    <div className="d-flex flex-wrap gap-3">
                      <button type="button" className="btn btn-outline-dark">
                        (316) *** **** - reveal
                      </button>
                      <a
                        className="btn btn-dark"
                        href="mailto:d.steward@example.com"
                      >
                        <i className="fi-mail fs-base me-2"></i>
                        Send email
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      )}
    </section>
  );
};

export default CarViewPage;
