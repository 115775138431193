import "./assets/sass/style.scss";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import HomePage from "./views/homepage/HomePage";
import SellCarPage from "./views/seller/SellCar";
import PersonalInfo from "./views/account/PersonalInfo";
import AccountLayout from "./layouts/AccountLayout";
import ProtectedRoute from "./_utils/protectedRoute";
import AuthProvider from "./context/AuthContext";
import DefaultLayout from "./layouts/DefaultLayout";
import CarListingPage from "./views/car-listing/CarListingPage";
import CarViewPage from "./views/car-listing/CarViewPage";
import { ToastProvider } from "./context/ToastContext";
import MyFavorites from "./views/account/favorites";
import MyCars from "./views/account/myCars";
import { WishlistProvider } from "./context/WishlistContext";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <ToastProvider>
          <WishlistProvider>
            <BrowserRouter>
              <Routes>
                {/* Public Pages */}
                <Route path="/" element={<DefaultLayout />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/auctions" element={<CarListingPage />} />
                  <Route path="/auctions/:id" element={<CarViewPage />} />

                  {/* Protected Pages */}
                  <Route
                    path="/sell-car"
                    element={
                      <ProtectedRoute>
                        <SellCarPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/account/"
                    element={
                      <ProtectedRoute>
                        <AccountLayout />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="profile" element={<PersonalInfo />} />
                    <Route path="favorites" element={<MyFavorites />} />
                    <Route path="my-listing" element={<MyCars />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </WishlistProvider>
        </ToastProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
